<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Broadcast Portal</h1>
      <div class=" flex justify-end items-end">
        <button class=" bg-blue-600 py-3 px-6 rounded-md  text-white">
          Add Template
        </button>
      </div>
      <div class="flex items-center justify-between">
        <input
          type="text"
          class="inline-block  px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
          name=""
          placeholder="Search by template name"
          id=""
          v-model="search"
        />
      </div>
      <div class="mt-10 pb-5" style="height: 400px; overflow:auto">
        <div
          class="w-11/12 border-2 border-gray-50 flex flex-col bg-white shadow-lg rounded-lg overflow-hidden"
        >
          <div class="bg-white px-6 py-4">
            <div class="flex items-center pt-3">
              <div
                class="bg-blue-700 w-12 h-12 flex justify-center items-center rounded-full uppercase font-bold text-white"
              >
                DPT
              </div>
              <div class="ml-4">
                <p class="font-bold text-blue-800">Delayed Payment Template</p>
                <p class="text-sm text-gray-700 mt-1">Created: 13 May,2021</p>
              </div>
            </div>
          </div>
          <div class="px-6 py-4 border-t border-gray-200">
            <div class="border text-sm rounded-lg p-4 bg-white">
              Dear Valued Customers, Payment is currently being delayed on our
              platforms, and your transactions might be taking a longer time
              than usual to fufill. Our team is currently working on restoring
              service and all pending transactions would be processed....
            </div>
          </div>
          <div
            class="text-sm text-right text-blue-900 text-gray-600 font-bold p-3"
          >
            View Template
          </div>
        </div>
        <div class="mt-10 pb-5" style="height: 400px; overflow:auto">
          <div
            class="w-11/12 border-2 border-gray-50 flex flex-col bg-white shadow-lg rounded-lg overflow-hidden"
          >
            <div class="bg-white px-6 py-4">
              <div class="flex items-center pt-3">
                <div
                  class="bg-blue-700 w-12 h-12 flex justify-center items-center rounded-full uppercase font-bold text-white"
                >
                  TN
                </div>
                <div class="ml-4">
                  <p class="font-bold text-blue-800">
                    Delayed Payment Template
                  </p>
                  <p class="text-sm text-gray-700 mt-1">Created: 13 May,2021</p>
                </div>
              </div>
            </div>
            <div class="px-6 py-4 border-t border-gray-200">
              <div class="border text-sm rounded-lg p-4 bg-white">
                Dear Valued Customers, Payment is currently being delayed on our
                platforms, and your transactions might be taking a longer time
                than usual to fufill. Our team is currently working on restoring
                service and all pending transactions would be processed....
              </div>
            </div>
            <div
              class="text-sm text-right text-blue-900 text-gray-600 font-bold p-3"
            >
              View Template
            </div>
          </div>
        </div>
      </div>
      <div class="mt-14 mb-14">
        <h3 class="text-xl text-blue-900">Frequently Used Templates</h3>
        <div class="flex flex-wrap xl:-mx-4 mb-10">
          <div class="w-1/3 xl:my-1 xl:px-4">
            <div
              class="w-full border-2 border-gray-50 flex flex-col bg-white shadow-lg rounded-lg overflow-hidden"
            >
              <div class="bg-white px-6 py-4">
                <div class="flex items-center pt-3">
                  <div
                    class="bg-blue-700 w-12 h-12 flex justify-center items-center rounded-full uppercase font-bold text-white"
                  >
                    TN
                  </div>
                  <div class="ml-4">
                    <p class="font-bold text-blue-800">
                      Delayed Payment Template
                    </p>
                    <p class="text-sm text-gray-700 mt-1">
                      Created: 13 May,2021
                    </p>
                  </div>
                </div>
              </div>
              <div class="px-6 py-4 border-t border-gray-200">
                <div class="border text-sm rounded-lg p-4 bg-white">
                  Dear Valued Customers, Payment is currently being delayed on
                  our platforms, and your transactions might be taking a longer
                  time than usual to fufill. Our team is currently working on
                  restoring service and all pending transactions would be
                  processed....
                </div>
              </div>
              <div
                class="text-sm text-right text-blue-900 text-gray-600 font-bold p-3"
              >
                View Template
              </div>
            </div>
          </div>

          <div class="w-1/3 xl:my-1 xl:px-4">
            <!-- Column Content -->
            <div
              class="w-full border-2 border-gray-50 flex flex-col bg-white shadow-lg rounded-lg overflow-hidden"
            >
              <div class="bg-white px-6 py-4">
                <div class="flex items-center pt-3">
                  <div
                    class="bg-blue-700 w-12 h-12 flex justify-center items-center rounded-full uppercase font-bold text-white"
                  >
                    TN
                  </div>
                  <div class="ml-4">
                    <p class="font-bold text-blue-800">
                      Delayed Payment Template
                    </p>
                    <p class="text-sm text-gray-700 mt-1">
                      Created: 13 May,2021
                    </p>
                  </div>
                </div>
              </div>
              <div class="px-6 py-4 border-t border-gray-200">
                <div class="border text-sm rounded-lg p-4 bg-white">
                  Dear Valued Customers, Payment is currently being delayed on
                  our platforms, and your transactions might be taking a longer
                  time than usual to fufill. Our team is currently working on
                  restoring service and all pending transactions would be
                  processed....
                </div>
              </div>
              <div
                class="text-sm text-right text-blue-900 text-gray-600 font-bold p-3"
              >
                View Template
              </div>
            </div>
          </div>

          <div class="w-1/3  xl:my-1 xl:px-4">
            <!-- Column Content -->
            <div
              class="w-full border-2 border-gray-50 flex flex-col bg-white shadow-lg rounded-lg overflow-hidden"
            >
              <div class="bg-white px-6 py-4">
                <div class="flex items-center pt-3">
                  <div
                    class="bg-blue-700 w-12 h-12 flex justify-center items-center rounded-full uppercase font-bold text-white"
                  >
                    TN
                  </div>
                  <div class="ml-4">
                    <p class="font-bold text-blue-800">
                      Delayed Payment Template
                    </p>
                    <p class="text-sm text-gray-700 mt-1">
                      Created: 13 May,2021
                    </p>
                  </div>
                </div>
              </div>
              <div class="px-6 py-4 border-t border-gray-200">
                <div class="border text-sm rounded-lg p-4 bg-white">
                  Dear Valued Customers, Payment is currently being delayed on
                  our platforms, and your transactions might be taking a longer
                  time than usual to fufill. Our team is currently working on
                  restoring service and all pending transactions would be
                  processed....
                </div>
              </div>
              <div
                class="text-sm text-right text-blue-900 text-gray-600 font-bold p-3"
              >
                View Template
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
    };
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
